import { render, staticRenderFns } from "./AddNewUserVenueAuthorizations.vue?vue&type=template&id=62ff6c30&scoped=true&"
import script from "./AddNewUserVenueAuthorizations.vue?vue&type=script&lang=ts&"
export * from "./AddNewUserVenueAuthorizations.vue?vue&type=script&lang=ts&"
import style0 from "./AddNewUserVenueAuthorizations.vue?vue&type=style&index=0&id=62ff6c30&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62ff6c30",
  null
  
)

export default component.exports