
























































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import AddNewUserVenueAuthorizations from "@/components/client-zone-users/AddNewUserVenueAuthorizations.vue";
import { required, email, maxLength, minLength } from "vuelidate/lib/validators";


export default Vue.extend({
  name: "company-detail" as string,

  components: {
    Layout,
    "add-user-venue-authorizations": AddNewUserVenueAuthorizations,
  },

  data() {
    return {
      email: "",
      firstName: "",
      lastName: "",
      jobPosition: "",
    }
  },

  //@ts-ignore
  validations: {
    email: { required, email },
    firstName: { required, minLength: minLength(3), maxLength: maxLength(20) },
    lastName: { required, minLength: minLength(3), maxLength: maxLength(20) },
    jobPosition: { required, minLength: minLength(3), maxLength: maxLength(40) },
  },



  async mounted(): Promise<void> {

  },

  computed: {

  },

  methods: {

  },

});
